import React, { useState, useEffect } from 'react';
import girlWebp from 'assets/images/girl.webp';
import girlJpg from 'assets/images/girl.jpg';
import LazyLoad from 'react-lazyload';
import loadable from '@loadable/component';

const FancyBox = loadable(() => import('./FancyBox'));
const FadeIn = loadable(() => import('../common/FadeIn'));

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ThumbnailBlock = () => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isImgLoading, setIsImgLoading ] = useState(false);
  const [ awaitImgLoad, setAwaitImgLoad ] = useState(false);

  const clickHandler = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isImgLoading) return;
    const timer = setTimeout(() => {
      setAwaitImgLoad(true);
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [ isImgLoading ]);

  return (
    <>
      <FancyBox youtubeVideoId='qf87O81GpOA' showPopup={isOpen} />
      <section className='thumbnail-block'>
        <div className='thumbnail-block__image-container'>
          <figure className='thumbnail-block__image img-relative'>
            {!awaitImgLoad && <div className='image-placeholder' style={{ aspectRatio: `${2385 / 1418}` }} />}
            <LazyLoad className={`${!awaitImgLoad ? 'img-absolute' : ''}`}>
              <FadeIn>
                <picture>
                  <source srcSet={girlWebp} type='image/webp' />
                  <source srcSet={girlJpg} type='image/jpg' />
                  <img
                    alt='girl'
                    src={girlJpg}
                    onLoad={() => setIsImgLoading(true)}
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <div className='thumbnail-block__overlay'>
            <div className='thumbnail-block__overlay-inner'>
              <button type='button' onClick={e => clickHandler(e)} className='btn btn--white' aria-label='Play the film'>
                <svg className='icon icon-play'>
                  <use xlinkHref='#icon-play' />
                </svg>
                Play the film
              </button>
              <div className='thumbnail-block__content'>
                <h2 className='thumbnail-block__title'>
                  <span>Air breathers<sup>TM</sup>,</span>
                  {' '}
                  <br />
                  rejoice.
                </h2>
                <div className='thumbnail-block__description'>
                  <p>
                    Ever met an air purifier you truly loved? Neither had we. So we created Mila—the first air purifier
                    designed with real, air-breathing humans in mind.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ThumbnailBlock;
